// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  selectAccount: path(ROOTS_AUTH, "/account-select"),
  login: path(ROOTS_AUTH, "/login"),
  loginDistributor: path(ROOTS_AUTH, "/login-distributor"),
  loginAdmin: path(ROOTS_AUTH, "/login-admin"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  sso: path(ROOTS_AUTH, "/sso"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  fileManager: path(ROOTS_DASHBOARD, "/files-manager"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  blank: path(ROOTS_DASHBOARD, "/blank"),
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
    file: path(ROOTS_DASHBOARD, "/file"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  customerCare: {
    root: path(ROOTS_DASHBOARD, "/customer-care"),
    portIn: path(ROOTS_DASHBOARD, "/customer-care/porting/port-in"),
    portingView: (msisdn, iccid, port_in_state) => path(ROOTS_DASHBOARD, `/customer-care/porting/${msisdn}?iccid=${iccid}&port_in_state=${port_in_state}`),
    simSwap: path(ROOTS_DASHBOARD, "/customer-care/sim-swap"),
    selfRicaList: path(ROOTS_DASHBOARD, "/customer-care/self-rica/list"),
    selfRicaView: (id) => path(ROOTS_DASHBOARD, `/customer-care/self-rica/${id}`),
    topup: path(ROOTS_DASHBOARD, "/customer-care/topup"),
    escalation: path(ROOTS_DASHBOARD, "/customer-care/escalation/list"),
    viewEscalation: (id) => path(ROOTS_DASHBOARD, `/customer-care/escalation/${id}`),
    createEscalation: path(ROOTS_DASHBOARD, "/customer-care/escalation/create"),
    unsubscribe: path(ROOTS_DASHBOARD, "/customer-care/unsubscribe/marketing"),
  },
  porting: {
    root: path(ROOTS_DASHBOARD, "/porting"),
    portIn: path(ROOTS_DASHBOARD, "/porting/port-in"),
    view: (msisdn, iccid, port_in_state) => path(ROOTS_DASHBOARD, `/porting/${msisdn}?iccid=${iccid}&port_in_state=${port_in_state}`),
  },
  wallets: {
    root: path(ROOTS_DASHBOARD, "/wallets"),
  },
  subscriptions: {
    root: path(ROOTS_DASHBOARD, "/subscriptions"),
    manageSubscriptions: path(ROOTS_DASHBOARD, "/subscriptions/manage-subscriptions"),
    subscriptionRequests: path(ROOTS_DASHBOARD, "/subscriptions/subscription-requests/list"),
    activeSubscriptions: path(ROOTS_DASHBOARD, "/subscriptions/active-subscriptions"),
    view: (id) => path(ROOTS_DASHBOARD, `/subscriptions/${id}`),
    viewSubscriptionRequests: (id) => path(ROOTS_DASHBOARD, `/subscriptions/subscription-requests/${id}`),
  },
  products: {
    root: path(ROOTS_DASHBOARD, "/products"),
    productList: path(ROOTS_DASHBOARD, "/products/list"),
    productGroupList: path(ROOTS_DASHBOARD, "/products/product-group/list"),
    viewProduct: (id) => path(ROOTS_DASHBOARD, `/products/${id}`),
    viewProductGroup: (id) => path(ROOTS_DASHBOARD, `/products/product-group/${id}`),
  },
  // selfRica: {
  //   root: path(ROOTS_DASHBOARD, "/self-rica"),
  //   list: path(ROOTS_DASHBOARD, "/self-rica/list"),
  //   view: (id) => path(ROOTS_DASHBOARD, `/self-rica/${id}`),
  //   form: (identifier = "") => path(ROOTS_DASHBOARD, `/self-rica/form/${identifier}`),
  // },
  // simSwap: {
  //   root: path(ROOTS_DASHBOARD, "/sim-swap"),
  // },
  userManagement: {
    root: path(ROOTS_DASHBOARD, "/user-management"),
  },
  rica: {
    root: path(ROOTS_DASHBOARD, "/rica"),
    list: path(ROOTS_DASHBOARD, "/rica/list"),
    // view: (id) => path(ROOTS_DASHBOARD, `/rica/${id}`),
    leads: path(ROOTS_DASHBOARD, "/rica/leads"),
    // selfRica: path(ROOTS_DASHBOARD, "/rica/self-rica/list"),
    selfRicaView: (id) => path(ROOTS_DASHBOARD, `/rica/self-rica/${id}`),
    selfRicaForm: path(ROOTS_DASHBOARD, "/rica/form"),
    port: path(ROOTS_DASHBOARD, "/rica/port"),
  },
  cx: {
    root: path(ROOTS_DASHBOARD, "/cx"),
    messaging: {
      root: path(ROOTS_DASHBOARD, "/cx/messaging"),
      list: path(ROOTS_DASHBOARD, "/cx/messaging/list"),
      view: (id) => path(ROOTS_DASHBOARD, `/cx/messaging/${id}`),
      create: path(ROOTS_DASHBOARD, "/cx/messaging/create"),
    },
    notices: {
      root: path(ROOTS_DASHBOARD, "/cx/notices"),
      list: path(ROOTS_DASHBOARD, "/cx/notices/list"),
      view: (id) => path(ROOTS_DASHBOARD, `/cx/notices/${id}`),
      create: path(ROOTS_DASHBOARD, "/cx/notices/create"),
    },
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    list: path(ROOTS_DASHBOARD, "/reports/list"),
    view: (id) => path(ROOTS_DASHBOARD, `/reports/${id}`),
  },
  iot: {
    root: path(ROOTS_DASHBOARD, "/iot"),
    list: path(ROOTS_DASHBOARD, "/iot/list"),
    view: (id, msisdn) => path(ROOTS_DASHBOARD, `/iot/${id}?msisdn=${msisdn}`),
    usage: path(ROOTS_DASHBOARD, "/iot/usage"),
  },
  simCards: {
    root: path(ROOTS_DASHBOARD, "/sim-cards"),
    scanOut: path(ROOTS_DASHBOARD, "/sim-cards/scan-out"),
    assignment: path(ROOTS_DASHBOARD, "/sim-cards/assignment"),
    orders: path(ROOTS_DASHBOARD, "/sim-cards/orders"),
    status: path(ROOTS_DASHBOARD, "/sim-cards/status"),
  },
  simScanOut: {
    root: path(ROOTS_DASHBOARD, "/sim-scanout"),
  },
  simAssignment: {
    root: path(ROOTS_DASHBOARD, "/sim-assignment"),
  },
  simOrders: {
    root: path(ROOTS_DASHBOARD, "/sim-orders"),
    list: path(ROOTS_DASHBOARD, "/sim-orders/list"),
    view: (id) => path(ROOTS_DASHBOARD, `/sim-orders/${id}`),
    create: path(ROOTS_DASHBOARD, "/sim-orders/create"),
  },
  distributors: {
    root: path(ROOTS_DASHBOARD, "/distributors"),
    list: path(ROOTS_DASHBOARD, "/distributors/list"),
    dashboard: path(ROOTS_DASHBOARD, "/distributors/dashboard"),
    graphs: path(ROOTS_DASHBOARD, "/distributors/graphs"),
    transactions: path(ROOTS_DASHBOARD, "/distributors/transactions"),
    simCards: path(ROOTS_DASHBOARD, "/distributors/sim-cards"),
    view: (id) => path(ROOTS_DASHBOARD, `/distributors/${id}`),
    statusList: path(ROOTS_DASHBOARD, "/distributors/status-list/list"),
    customerView: (id) => path(ROOTS_DASHBOARD, `/distributors/status-list/${id}`),
    salesReport: path(ROOTS_DASHBOARD, "/distributors/sales-report"),
    distributorReport: path(ROOTS_DASHBOARD, "/distributors/distributor-report"),
    voucherList: path(ROOTS_DASHBOARD, "/distributors/vouchers/list"),
    voucherView: (id) => path(ROOTS_DASHBOARD, `/distributors/vouchers/${id}`),
  },
  resellers: {
    root: path(ROOTS_DASHBOARD, "/resellers"),
    list: path(ROOTS_DASHBOARD, "/resellers/list"),
    dashboard: path(ROOTS_DASHBOARD, "/resellers/dashboard"),
    transactions: path(ROOTS_DASHBOARD, "/resellers/transactions"),
    sellProduct: path(ROOTS_DASHBOARD, "/resellers/sell-product"),
    view: (id) => path(ROOTS_DASHBOARD, `/resellers/${id}`),
  },
  vouchers: {
    root: path(ROOTS_DASHBOARD, "/vouchers"),
    list: path(ROOTS_DASHBOARD, "/vouchers/list"),
    view: (id) => path(ROOTS_DASHBOARD, `/vouchers/${id}`),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, "/admin"),
    adminUsers: path(ROOTS_DASHBOARD, "/admin/admin-users"),
    appUsers: path(ROOTS_DASHBOARD, "/admin/app-users"),
    edit: (id) => path(ROOTS_DASHBOARD, `/admin/app-users/${id}`),
    distributorNotices: path(ROOTS_DASHBOARD, "/admin/distributor-notices"),
    createNotice: path(ROOTS_DASHBOARD, "/admin/distributor-notices/create"),
    viewNotice: (id) => path(ROOTS_DASHBOARD, `/admin/distributor-notices/${id}`),
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, "/e-commerce/product/nike-blazer-low-77-vintage/edit"),
    demoView: path(ROOTS_DASHBOARD, "/e-commerce/product/nike-air-force-1-ndestrukt"),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"),
    demoView: path(ROOTS_DASHBOARD, "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, "/blog/post/apply-these-7-secret-techniques-to-improve-event"),
  },
  webhooks: {
    root: path(ROOTS_DASHBOARD, "/webhooks"),
    manage: path(ROOTS_DASHBOARD, "/webhooks/manage"),
  },
  trainingVideos: {
    root: path(ROOTS_DASHBOARD, "/training-videos"),
    view: (id, type) => path(ROOTS_DASHBOARD, `/training-videos/${type}/${id}`),
    list: path(ROOTS_DASHBOARD, "/training-videos/list"),
    addVideos: path(ROOTS_DASHBOARD, "/training-videos/add"),
    agentVideos: path(ROOTS_DASHBOARD, "/training-videos/agent-videos"),
    consumerVideos: path(ROOTS_DASHBOARD, "/training-videos/consumer-videos"),
  },
  subDistributors: {
    root: path(ROOTS_DASHBOARD, "/sub-distributors"),
    list: path(ROOTS_DASHBOARD, "/sub-distributors/list"),
    levels: (i_customer, id, level) => path(ROOTS_DASHBOARD, `/sub-distributors/${i_customer}/id/${id}/level/${level}`),
  },
  ruleManagement: {
    root: path(ROOTS_DASHBOARD, "/rule-management"),
    addRule: path(ROOTS_DASHBOARD, "/rule-management/new"),
  },
};

export const PATH_DOCS = {
  root: "https://docs.minimals.cc",
  changelog: "https://docs.minimals.cc/changelog",
};

export const PATH_ZONE_ON_STORE = "https://mui.com/store/items/zone-landing-page/";

export const PATH_MINIMAL_ON_STORE = "https://mui.com/store/items/minimal-dashboard/";

export const PATH_FREE_VERSION = "https://mui.com/store/items/minimal-dashboard-free/";

export const PATH_FIGMA_PREVIEW = "https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1";
