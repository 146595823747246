import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  Tabs,
  InputAdornment,
  Tab,
} from "@mui/material";
import { useEffect, useState } from "react";

import { listAdminAppUsers, listAllAppUsers, listAllProducts, listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import ProductsTableRow from "./ProductsTableRow";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Label from "src/components/label";
export default function ProductList({ productTypes }) {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [productsDataForTabs, setProductsDataForTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState({ id: "All", name: "All" });
  const [activeCount, setActiveCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [tabStatus, setTabStatus] = useState("active");

  const getLengthByStatus = (status_code) => productsDataForTabs.filter((item) => item.is_active === status_code).length;

  const TABLE_HEAD = [
    { id: "name", label: "Name", align: "left" },
    // { id: "role", label: "Role", align: "left" },
    { id: "product_type_name", label: "Product Type Name", align: "left" },
    { id: "activation_fee", label: "Activation Fee", align: "right" },
    { id: "airtime_volume", label: "Airtime Volume", align: "right" },
    { id: "data_volume", label: "Data Volume", align: "right" },
    { id: "expiry", label: "Expiry", align: "right" },
    { id: "is_active", label: "Status", align: "left" },
  ];

  const TABS = [
    { value: "all", label: "All", color: "primary", count: productsDataForTabs.length },
    { value: "active", label: "Active", color: "success", count: getLengthByStatus(1) },
    { value: "inactive", label: "Inactive", color: "primary", count: getLengthByStatus(0) },
  ];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    console.log("Inside listAllProducts1");
    async function fetchProducts() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await listAllProducts();
        if (response.status_code !== 1001) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setProducts(data.sort((a, b) => a.activation_fee - b.activation_fee));
        setProductsData(data);
        setProductsDataForTabs(data);
      } catch (error) {
        console.log("error", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchProducts();
  }, []);

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue);
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.products.viewProduct(id));
  };

  const handleTabStatus = (event, newValue) => {
    setTabStatus(newValue);
  };

  useEffect(() => {
    const filteredProductsData = productsData?.filter((product) => {
      const isStatusMatch =
        tabStatus === "all" || (tabStatus === "active" && product?.is_active === 1) || (tabStatus === "inactive" && product?.is_active === 0);

      const isTypeMatch = selectedType?.id === "All" || product?.product_type_id === selectedType?.id;

      const isSearchMatch =
        product?.name?.toLowerCase().includes(search.toLowerCase()) ||
        product?.product_type_name?.toLowerCase().includes(search.toLowerCase()) ||
        product?.expiry?.toString().includes(search.toString()) ||
        product?.data_volume?.toString().includes(search.toString()) ||
        product?.airtime_volume?.toString().includes(search.toString()) ||
        product?.activation_fee?.toString().includes(search.toString());

      return isStatusMatch && isTypeMatch && isSearchMatch;
    });

    setProducts([...filteredProductsData]);
  }, [selectedType, search, tabStatus, productsData]);

  if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          value={tabStatus}
          onChange={handleTabStatus}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Label color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />
        <Grid container spacing={2}>
          <Grid item sx={{ m: 2 }} xs={4}>
            <Autocomplete
              value={selectedType}
              onChange={handleTypeChange}
              options={productTypes}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => <TextField {...params} label="Select Product Type" placeholder="Select Product Type" fullWidth />}
            />
          </Grid>
          <Grid item sx={{ m: 2 }} xs={7}>
            <TextField
              fullWidth
              value={search}
              placeholder="Search..."
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={products.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => (
                  <ProductsTableRow key={index} row={product} onViewRow={() => handleViewRow(product.id)} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, products.length)} />
                <TableNoData isNotFound={products.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={products.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
