// i18n
import "./locales/i18n";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
/* eslint-disable import/no-unresolved */
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

// map
import "mapbox-gl/dist/mapbox-gl.css";
import "./utils/mapboxgl";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "./sections/@dashboard/self-rica/details/style.css";
import "src/sections/@dashboard/distributor-notices/create/style.css";

// react-query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
// @mui
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
// redux
import { persistor, store } from "./redux/store";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { MotionLazyContainer } from "./components/animate";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { SettingsProvider, ThemeSettings } from "./components/settings";
import SnackbarProvider from "./components/snackbar";
import { AuthProvider } from "./auth/JwtContext";

// ------------ MUI X ------------
import { LicenseInfo } from "@mui/x-license-pro";

const MUI_LICENCE_KEY = process.env.REACT_APP_MUI_X_LICENCE_KEY;

LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

const firebaseConfig = {
  apiKey: "AIzaSyCE0IrWlyJnPMD0EZrkC0bvT-gr2sFO-rA",
  authDomain: "megsapp-portal.firebaseapp.com",
  projectId: "megsapp-portal",
  storageBucket: "megsapp-portal.appspot.com",
  messagingSenderId: "663831847499",
  appId: "1:663831847499:web:cdc7a38979868df46be3ce",
  measurementId: "G-BTSNXHSJW5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// ----------------------------------------------------------------------

// Check our docs
// https://docs.minimals.cc/authentication/js-version

// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------
const queryClient = new QueryClient();

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <ScrollToTop />
                  <MotionLazyContainer>
                    <ThemeProvider>
                      <ThemeSettings>
                        <ThemeLocalization>
                          <SnackbarProvider>
                            <StyledChart />
                            <QueryClientProvider client={queryClient}>
                              <Router />
                            </QueryClientProvider>
                          </SnackbarProvider>
                        </ThemeLocalization>
                      </ThemeSettings>
                    </ThemeProvider>
                  </MotionLazyContainer>
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
