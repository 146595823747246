import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  Tabs,
  InputAdornment,
  Tab,
} from "@mui/material";
import { useEffect, useState } from "react";

import { listAdminAppUsers, listAllAppUsers, listAllProductGroup, listAllProducts, listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import ProductGroupTableRow from "./ProductGroupTableRow";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router-dom";
import Label from "src/components/label";
export default function ProductGroupList({ distributors, selectedRole, onChangeDistributor }) {
  const navigate = useNavigate();
  const [productGroups, setProductGroups] = useState([]);
  const [productGroupsData, setProductGroupsData] = useState([]);
  const [productGroupsDataForTabs, setProductGroupsDataForTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState();
  const [tabStatus, setTabStatus] = useState("all");

  // const [selectedDistributor, setSelectedDistributor] = useState(selectedRole.role === "admin" ? null : distributors[0]);

  // const handleDistributorChange = (event, newValue) => {
  //   console.log("Distributor Values", newValue);
  //   setSelectedDistributor(newValue);
  // };
  /*{
            "name": "All Products",
            "airtime": 1,
            "data": 9,
            "data_specials": 19,
            "subscriptions": 2
        }, */

  const TABLE_HEAD = [
    { id: "name", label: "Name", align: "left" },
    { id: "airtime", label: "Airtime", align: "left" },
    { id: "data", label: "Data", align: "left" },
    { id: "data_specials", label: "Data Specials", align: "left" },
    { id: "subscriptions", label: "Subscriptions", align: "left" },
  ];

  const getLengthByStatus = (status_code) => productGroupsDataForTabs.filter((item) => item.is_active === status_code).length;

  const TABS = [
    { value: "all", label: "All", color: "primary", count: productGroupsDataForTabs.length },
    // {
    //   value: "active",
    //   label: "Active",
    //   color: "success",
    //   count: getLengthByStatus(1),
    // },
    // {
    //   value: "inactive",
    //   label: "Inactive",
    //   color: "primary",
    //   count: getLengthByStatus(0),
    // },
  ];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    console.log("Inside listAllProducts1");
    async function fetchProductGroups() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await listAllProductGroup();
        console.log("response", response);
        if (response.status_code !== 1001) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        console.log("Products Data", data);
        setProductGroups(data);
        setProductGroupsData(data);
        setProductGroupsDataForTabs(data);
      } catch (error) {
        console.log("error", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchProductGroups();
  }, []);

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDistributorChange = (event, newValue) => {
    setSelectedDistributor(newValue);
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.products.viewProductGroup(id));
  };

  const handleTabStatus = (event, newValue) => {
    setTabStatus(newValue);
  };

  useEffect(() => {
    const filteredProductsData = productGroupsData?.filter((product) => {
      const isStatusMatch =
        tabStatus === "all" || (tabStatus === "active" && product?.is_active === 1) || (tabStatus === "inactive" && product?.is_active === 0);

      const isSearchMatch = product?.name?.toLowerCase().includes(search.toLowerCase());

      return isStatusMatch && isSearchMatch;
    });

    setProductGroups([...filteredProductsData]);
  }, [search, tabStatus]);

  if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          value={tabStatus}
          onChange={handleTabStatus}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Label color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />
        <Grid container spacing={2}>
          <Grid item sx={{ m: 2 }} xs={12}>
            <TextField
              fullWidth
              value={search}
              placeholder="Search..."
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={productGroups.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {productGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((productGroup, index) => (
                  <ProductGroupTableRow key={index} row={productGroup} onViewRow={() => handleViewRow(productGroup.id)} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, productGroups.length)} />
                <TableNoData isNotFound={productGroups.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={productGroups.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
