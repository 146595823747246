import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  // console.log("NavAccount.js: user: ", user);
  const userRoles = useSelector((state) => state.auth.user.roles);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  // console.log("NavAccount.js: userRoles: ", userRoles);
  // console.log("NavAccount.js: selectedRole: ", selectedRole);

  // console.log("User: ", user);
  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.user.account} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar alt={selectedRole.customer_name} name={""}>
          <Icon icon="eva:person-fill" />
        </CustomAvatar>

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {/* if user.type is admin show username */}
            {user?.type === "admin" ? user?.username : selectedRole.customer_name}

            {/* {user?.username} */}
          </Typography>

          {/* <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {selectedRole?.customer_name || user?.username}
          </Typography> */}
        </Box>
      </StyledRoot>
    </Link>
  );
}
