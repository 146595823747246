import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Card, Container, Grid, Stack } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addAdminUser, addAppUser, addLeveledSubDistributor } from "src/api/megs-api-api";
import { FormProvider, RHFAutocomplete, RHFTextField } from "src/components/hook-form";
import { useSnackbar } from "src/components/snackbar";
import * as Yup from "yup";
import { PATH_DASHBOARD } from "src/routes/paths";
import PropTypes from "prop-types";

const AddLeveledSubDistributor = ({ parent }) => {
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { distributor_i_customer, id, level } = useParams();

  const SubDistributorSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_first_name: Yup.string().required("First Name is required"),
    contact_last_name: Yup.string().required("Last Name is required"),
    contact_phone_number: Yup.string().required("Phone Number is required"),
    contact_email_address: Yup.string().email("Please enter valid email address").required("Email Address is required"),
    commission_ogr: Yup.object().required("OGR Commission is required"),
    commission_sales: Yup.object().required("API Commission is required"),
  });

  const generateSequence = () => {
    const commissionValues = [];
    for (let i = 0; i <= parent?.commission_ogr; i += 0.5) {
      commissionValues.push({
        value: i,
        label: `${i}%`,
      });
    }
    return commissionValues;
  };

  const generateSequenceAPI = () => {
    const commissionValues = [];
    for (let i = 0; i <= parent?.commission_sales; i += 0.5) {
      commissionValues.push({
        value: i,
        label: `${i}%`,
      });
    }
    return commissionValues;
  };

  const defaultValues = {
    name: "",
    contact_first_name: "",
    contact_last_name: "",
    contact_phone_number: "",
    contact_email_address: "",
    commission_ogr: { value: 0, label: "0%" },
    commission_sales: { value: 0, label: "0%" },
  };

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(SubDistributorSchema),
    defaultValues,
    shouldFocusError: true,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  console.log("values", values);

  const onProcessSubmit = useCallback(async () => {
    const result = await addLeveledSubDistributor({
      distributor_i_customer: Number(distributor_i_customer),
      level: Number(level),
      ...(Number(level) !== 1 && { parent_id: Number(id) }),
      name: values.name,
      contact_first_name: values.contact_first_name,
      contact_last_name: values.contact_last_name,
      contact_phone_number: values.contact_phone_number,
      contact_email_address: values.contact_email_address,
      commission_ogr: values.commission_ogr?.value,
      commission_sales: values.commission_sales?.value,
    });

    if (result.status_code === 1001) {
      navigate(PATH_DASHBOARD.subDistributors.list);
      methods.setValue("name", "");
      methods.setValue("contact_first_name", "");
      methods.setValue("contact_last_name", "");
      methods.setValue("contact_phone_number", "");
      methods.setValue("contact_email_address", "");
      methods.setValue("commission_ogr", "");
      methods.setValue("commission_sales", "");
      enqueueSnackbar(result.data, { variant: "success" });
    } else {
      enqueueSnackbar(result.data.message, { variant: "error" });
    }
  }, [values, methods, enqueueSnackbar]);

  return (
    <>
      <Card sx={{ p: 3, mb: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onProcessSubmit)}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item sm={6} sm={3}>
              <RHFTextField name="name" label="Name" />
            </Grid>
            <Grid item sm={6} sm={3}>
              <RHFTextField name="contact_first_name" label="First Name" />
            </Grid>
            <Grid item sm={6} sm={3}>
              <RHFTextField name="contact_last_name" label="Last Name" />
            </Grid>
            <Grid item sm={6} sm={3}>
              <RHFTextField name="contact_email_address" label="Email Address" />
            </Grid>
            <Grid item sm={6} sm={3}>
              <RHFTextField name="contact_phone_number" label="Phone Number" />
            </Grid>
            <Grid item sm={6} sm={3}>
              <RHFAutocomplete name="commission_ogr" label="OGR %" placeholder="OGR %" options={generateSequence()} />
            </Grid>
            <Grid item sm={6} sm={3}>
              <RHFAutocomplete name="commission_sales" label="API %" placeholder="API %" options={generateSequenceAPI()} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
                <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onProcessSubmit)} disabled={errors.length > 0}>
                  Add
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </>
  );
};

export default AddLeveledSubDistributor;
