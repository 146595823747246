import { Box, Card, CircularProgress, Table, TableBody, TableContainer, Grid, Autocomplete, TextField, Divider, Tabs, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";

import { listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import AppUserTableRow from "./AppUserTableRow";
export default function AppUsersList({ distributors, selectedRole, onChangeDistributor }) {
  const [appUsers, setAppUsers] = useState([]);
  const [appUsersData, setAppUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { i_customer } = selectedRole;
  const [selectedDistributor, setSelectedDistributor] = useState();

  // const [selectedDistributor, setSelectedDistributor] = useState(selectedRole.role === "admin" ? null : distributors[0]);

  // const handleDistributorChange = (event, newValue) => {
  //   console.log("Distributor Values", newValue);
  //   setSelectedDistributor(newValue);
  // };

  const TABLE_HEAD = [
    { id: "username", label: "Username", align: "left" },
    { id: "name", label: "Name", align: "left" },
    // { id: "role", label: "Role", align: "left" },
    { id: "msisdn", label: "Mobile", align: "left" },
    { id: "role", label: "Role", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ];

  const TABS = [{ value: "all", label: "All", color: "primary", count: appUsers.length }];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    if (selectedDistributor) {
      const distributorId = selectedRole.role === "admin" ? selectedDistributor?.i_customer : selectedRole.i_customer;
      async function fetchAppUsers() {
        setIsLoading(true);
        setError(null);
        try {
          const response = await listAppUsers(distributorId);
          // console.log("response", response);
          if (response.status_code !== 1001) {
            throw new Error("Network response was not ok");
          }
          const data = await response.data;
          // console.log("App Users", data);
          setAppUsers(data);
          setAppUsersData(data);
        } catch (error) {
          console.log("error", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }

      fetchAppUsers();
      onChangeDistributor(selectedDistributor);
    }
  }, [selectedDistributor]);

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDistributorChange = (event, newValue) => {
    setSelectedDistributor(newValue);
  };

  useEffect(() => {
    if (distributors.length) {
      if (selectedRole.role === "admin") {
        setSelectedDistributor({ company_name: "All", i_customer: "All" });
      } else {
        const distributor = distributors.find((d) => d.i_customer === selectedRole.i_customer);
        setSelectedDistributor(distributor);
      }
    }
  }, [distributors]);

  useEffect(() => {
    const filteredAppUsers = appUsersData.filter((appUser) => appUser.username.toLowerCase().includes(search.toLowerCase()));
    setAppUsers([...filteredAppUsers]);
  }, [search]);

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.05)",
          zIndex: 9999,
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        />{" "}
        <Grid container spacing={2}>
          {selectedRole.role === "admin" && (
            <Grid item sx={{ m: 2 }} xs={6}>
              <Autocomplete
                value={selectedDistributor}
                onChange={handleDistributorChange}
                options={distributors}
                getOptionLabel={(option) => option.company_name || ""}
                renderInput={(params) => <TextField {...params} label="Select Distributor" placeholder="Select Distributor" fullWidth />}
              />
            </Grid>
          )}
          <Grid item sx={{ m: 2 }} xs={selectedRole.role === "admin" ? 5 : 12}>
            <TextField
              fullWidth
              value={search}
              placeholder="Search Username..."
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={appUsers.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {appUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                  <AppUserTableRow key={`${user.id} - ${user.role} - ${user.i_customer}`} row={user} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, appUsers.length)} />
                <TableNoData isNotFound={appUsers.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={appUsers.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
