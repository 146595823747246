import { createSlice } from "@reduxjs/toolkit";

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("auth");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.warn("Failed to load state from localStorage");
    return undefined;
  }
};

const persistedState = loadState();

const initialState = persistedState || {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  selectedRole: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialize: (state, action) => {
      state.isInitialized = true;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.selectedRole = state.user && state.user.roles && state.user.roles.length > 0 ? state.user.roles[0] : null;

      // Save state to localStorage
      localStorage.setItem("auth", JSON.stringify(state));
    },
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.selectedRole = state.user && state.user.roles && state.user.roles.length > 0 ? state.user.roles[0] : null;

      // Save state to localStorage
      localStorage.setItem("auth", JSON.stringify(state));
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.selectedRole = null;

      // Remove state from localStorage
      localStorage.removeItem("auth");
    },
    setRole: (state, action) => {
      console.log(action.payload);
      state.selectedRole = action.payload;
      if (state.user) {
        state.user.type = action.payload.role;

        // Save state to localStorage
        localStorage.setItem("auth", JSON.stringify(state));
      }
    },
  },
});

export const { initialize, login, logout, setRole } = authSlice.actions;
export default authSlice.reducer;
