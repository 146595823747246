import PropTypes from "prop-types";
import { useEffect, useState, useRef, useLayoutEffect } from "react";

// @mui
import { Button, Card, Checkbox, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "react-redux";
import { updateProductGroupMapping, updateProductStatus } from "src/api/megs-api-api";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

ProductDetails.propTypes = {
  productData: PropTypes.object,
};

export default function ProductDetails({ productData, productGroups, productId }) {
  const {
    id,
    name,
    activation_fee,
    airtime_volume,
    data_volume,
    in_app_extra,
    in_app_extra_percentage,
    expiry,
    expiry_period,
    add_on_priority,
    product_type_name,
    is_active,
  } = productData;
  console.log("Details: ", productData);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [associatedProductGroups, setAssociatedProductGroups] = useState([]);
  const [associatedId, setAssociatedId] = useState([]);
  const [associatedProductGroupsData, setAssociatedProductGroupsData] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isActive, setIsActive] = useState();

  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const productGroupIds = [];
    const productsObject = productData?.product_group_products?.map((product) => {
      productGroupIds.push(product.product_group_id);
      const { name, ...newProduct } = product;
      newProduct.isDeleted = false;
      return newProduct;
    });
    setAssociatedProductGroupsData(productsObject);
    setAssociatedProductGroups(productGroupIds);
    setAssociatedId(productGroupIds);
  }, [productData]);

  useEffect(() => {
    setIsActive(is_active);
  }, [is_active]);

  const handleAssociation = (e, id) => {
    setIsDirty(true);
    setAssociatedProductGroups(e.target.checked ? [...associatedProductGroups, id] : associatedProductGroups.filter((groupId) => groupId !== id));

    if (associatedId.includes(id)) {
      const newData = associatedProductGroupsData.map((data) => {
        if (data.product_group_id == id) {
          data.isDeleted = e.target.checked ? false : true;
        }
        return data;
      });

      setAssociatedProductGroupsData(newData);
    } else {
      let productNew = {
        id: null,
        product_id: parseInt(productId),
        product_group_id: id,
        isDeleted: false,
      };
      setAssociatedProductGroupsData(
        e.target.checked ? [...associatedProductGroupsData, productNew] : associatedProductGroupsData.filter((data) => data.product_group_id !== id)
      );
    }
  };

  const updateProductGroups = async () => {
    try {
      setIsLoading(true);
      const response = await updateProductGroupMapping(associatedProductGroupsData);
      console.log("response", response);
      if (response.status_code === 1001) {
        enqueueSnackbar("Product groups updated successfully!", { variant: "success" });
        setTimeout(() => {
          closeSnackbar();
        }, 4000);
        navigate(PATH_DASHBOARD.products.viewProduct(productId));
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchChange = async (event) => {
    const newStatus = event.target.checked ? 1 : 0;
    setIsActive(newStatus);
    const response = await updateProductStatus({ id: parseInt(productId), is_active: newStatus });
    if (response.status_code !== 1001) {
      enqueueSnackbar("Unable to update status", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setTimeout(() => {
        closeSnackbar();
      }, 4000);
    } else {
      enqueueSnackbar("Status updated successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setTimeout(() => {
        closeSnackbar();
      }, 4000);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
              Product Details
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Product ID</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {id}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Name</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Product Type Name</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {product_type_name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Activation Fee</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {activation_fee !== null ? activation_fee : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Airtime Volume</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {airtime_volume !== null ? airtime_volume : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Data Volume</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {data_volume !== null ? data_volume : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">In App Extra</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {in_app_extra !== null ? in_app_extra : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">In App Extra Percentage</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {in_app_extra_percentage !== null ? in_app_extra_percentage : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Expiry</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {expiry !== null ? expiry : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Expiry Period</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {expiry_period !== null ? expiry_period : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle2">Add On Priority</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {add_on_priority !== null ? add_on_priority : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel control={<Switch checked={isActive} onChange={handleSwitchChange} name="active" />} label="Active" />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
              Associated Product Groups
            </Typography>
            <Grid container spacing={3}>
              {productGroups.map((group) => (
                  <Grid item xs={12} md={2} key={group.id}>
                    <FormControlLabel
                      control={<Checkbox checked={associatedProductGroups.includes(group.id)} onChange={(e) => handleAssociation(e, group.id)} />}
                      label={group.name}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  disabled={!isDirty}
                  onClick={() => {
                    updateProductGroups();
                  }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
